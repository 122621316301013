import React from 'react';
import get from 'lodash.get';
import { injectIntl } from 'gatsby-plugin-react-intl';

import {
    Spacing,
    HeroSection,
    Slider,
    SliderTools,
    SharedStyles,
    Container,
    ListOfImg,
    ImageLink,
} from '../kenra-storybook/index';

const { StSliderTools } = SliderTools;
const { StSectionTitle, StLink } = SharedStyles;

const COLOR_MANUAL_TYPE = 'Color manual';
const APPLICATION_CARD_TYPE = 'Application card';

const Tools = ({ intl, tools }) => {
    let manuals = tools.filter(tool => tool.type === COLOR_MANUAL_TYPE);
    let cards = tools.filter(tool => tool.type === APPLICATION_CARD_TYPE);
    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottom>
                <HeroSection
                    title={intl.formatMessage({ id: 'stylist-tools-title' })}
                    bgImg={`/images/stylist_tools_banner.jpg`}
                />
            </Spacing>

            <Spacing>
                <StSectionTitle>
                    <Container>
                        <h2>
                            {intl.formatMessage({
                                id: 'stylist-manuals-title',
                            })}
                        </h2>
                    </Container>
                </StSectionTitle>

                <Slider settings={{ slidesPerView: 'auto' }}>
                    {manuals.map((manual, index) => (
                        <StSliderTools key={index}>
                            <img
                                src={get(
                                    manual,
                                    'image.localFile.square.resize.src'
                                )}
                                alt=""
                            />
                            <h3>{manual.title}</h3>
                            <ImageLink
                                image="/images/download.png"
                                text={intl.formatMessage({
                                    id: 'download-text',
                                })}
                                link={get(manual, 'file.localFile.publicURL')}
                            />
                        </StSliderTools>
                    ))}
                </Slider>
            </Spacing>

            <Spacing>
                <section>
                    <StSectionTitle>
                        <Container>
                            <h2>
                                {intl.formatMessage({
                                    id: 'application-cards-title',
                                })}
                            </h2>
                        </Container>
                    </StSectionTitle>
                    <ListOfImg
                        // btnMoreHref="/"
                        items={[
                            ...cards.map(card => ({
                                title: card.title,
                                img: get(card, 'image.localFile.publicURL'),
                                styleWidth50: !!card.wide,
                                href: `/application/${card.title}`,
                            })),
                        ]}
                    />
                </section>
            </Spacing>
        </>
    );
};

export default injectIntl(Tools);
