import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Tools from 'components/Tools';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function ToolsPage(props) {
    let {
        data: { allContentfulTool, contentfulPage },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulPage, 'seoListing.seoTitle')}
                slug={'tools'}
            />
            <Tools tools={allContentfulTool.nodes} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        allContentfulTool(filter: { node_locale: { eq: $locale } }) {
            nodes {
                ...ContentfulToolFields
            }
        }
        contentfulPage(title: { eq: "Tools" }, node_locale: { eq: $locale }) {
            ...ContentfulPageFields
        }
    }
`;
